.stake-table tr:last-child {
    border: none;
}

button:disabled,
button[disabled] {
    opacity: .5;
    cursor: not-allowed;
}

.animate-bg {
	background: linear-gradient(-45deg, #ffa434, #902e54, #a9520f, #c83936);
	background-size: 400% 400%;
	animation: gradient 5s ease infinite;
}
.pool-name{
	font-size: 13px !important;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}