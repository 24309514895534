@tailwind base;
@tailwind components;
@tailwind utilities;
.loader {
    border: 4px solid #50b5e2;
    border-radius: 50%;
    border-top: 4px solid #af38c7;
    width: 30px;
    height: 30px;
    text-indent: -9999em;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}
html {
    scroll-behavior: smooth;
    --main-bg-color-900: #272636;
    --main-bg-color-600: #353444;
    --main-bg-color-300: #48495F;
}

.main-bg-color-900{
    background-color: var(--main-bg-color-900);
}

.main-bg-color-600{
    background-color: var(--main-bg-color-600);
}

.main-bg-color-300{
    background-color: var(--main-bg-color-300);
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.button--gradient {
    background: linear-gradient(136.67deg, #FF409A 8.34%, #C438EF 95.26%);
    box-shadow: 0px 4px 31px #BA1358;
}

@media (min-width: 640px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 320px) {
    #root, #root > div {
        min-width: 1200px;
    }
}